// import {useEffect, useState} from 'react';
import useWindowDimensions from './useWindowDimensions';

/**
 * Determine height and width of window screen
 * @returns
 */
export default function useCheckBreakpoint() {
  const dimension = useWindowDimensions();
  let isDesktop = dimension?.width >= 1024;
  let isTablet = dimension?.width < 1024 && dimension?.width >= 768;
  let isMobile = !isDesktop && !isTablet;

  return {
    isDesktop,
    isTablet,
    isMobile,
  };
}
