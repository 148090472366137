import React from 'react';
import {Script, ScriptStrategy} from 'gatsby';
import Layout from './src/components/Layout/Layout';
import {FirebaseAuthContextProvider} from './src/store/firebase-auth-context';

// Create shared components for wrapPageElement API of Gatsby SSR and Gatsby Browser
export const wrapPageElement = ({element, props}) => {
  const isRenderGoogleAnalytics = !!process.env.GATSBY_GG_ANALYTICS_TRACKING_ID;
  return (
    <Layout {...props}>
      {element}

      {/* Script Area */}

      {/* GG Tag */}
      {isRenderGoogleAnalytics && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GG_ANALYTICS_TRACKING_ID}`}
            strategy={ScriptStrategy.idle}
          />
          <Script id="gtm-init" strategy={ScriptStrategy.idle}>
            {`  
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${process.env.GATSBY_GG_ANALYTICS_TRACKING_ID}');
            `}
          </Script>
        </>
      )}
    </Layout>
  );
};

export const wrapRootElement = ({element}) => {
  return <FirebaseAuthContextProvider>{element}</FirebaseAuthContextProvider>;
};
