import React from 'react';
import useCheckBreakpoint from '../../hooks/useCheckBreakpoint';
// import lazy
// const OverlayMenu = lazy(() => import('../OverlayMenu/OverlayMenu'));

/**
 *
 * @param {Object} props - React Props
 * @param {Object} props.children - children element
 * @param {Object} props.location - Store location URL
 * @returns
 */
const Layout = ({children, location}) => {
  const isSSR = typeof window === 'undefined';

  const {isDesktop, isTablet, isMobile} = useCheckBreakpoint();

  const styleBaseSettings = 'text-base-text bg-transparent';
  const styleTextSelection =
    'selection:bg-text-selection-background selection:text-text-selection';

  return (
    <div
      className={`${styleBaseSettings} ${styleTextSelection} overflow-x-clip  `}
    >
      {/* <Header headerHeight="h-[70px] lg:h-[80px]" /> */}
      <main className="flex min-h-[100vh] items-stretch justify-center">
        {children}
      </main>
      {/* <Footer /> */}

      {/* Floating buttons */}
      {/* {isDesktop ? (
        // Only visible on Desktop
        <ScrollToTopButton
          className={`fixed bottom-6 right-0 z-10 mx-4 h-fit`}
        />
      ) : (
        // Only visible on Tablet & mobile
        <BottomToolBar className="fixed bottom-6 right-0 z-10" />
      )} */}

      {/* Lazy Components */}
      {/* {!isSSR && (
        <Suspense fallback={<div />}>
          {!isDesktop && (
            <></>
            // Only visible on Tablet & mobile
            // <OverlayMenu />
          )}
        </Suspense>
      )} */}
    </div>
  );
};

export default Layout;
